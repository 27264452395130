import styled, { css } from "styled-components";

import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";

import { SlideBarSide } from "./SlideBar";

export const Backdrop = styled.div`
  background: rgba(22, 8, 41, 0.4);
  backdrop-filter: blur(2px);
  height: 100%;
  opacity: 0;
  transition: all 0.25s ease;
  visibility: hidden;
  width: 100%;
`;

export const Close = styled.button`
  background: transparent;
  border: none;
  color: #8d889e;
  cursor: pointer;
  height: 32px;
  padding: 0;
  position: absolute;
  right: 16px;
  top: 16px;
  width: 32px;

  .slide-bar__close-icon {
    height: 100%;
    width: 100%;
  }

  @media screen and ${MEDIA_QUERY.md} {
    height: 40px;
    width: 40px;
  }
`;

export const Container = styled.div<{ dark?: boolean; side: SlideBarSide; width?: number }>`
  background: #fff;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  transform: translateY(100%);
  transition: transform 0.25s ease;
  width: 100%;

  ${({ dark }) =>
    dark
      ? css`
          background: rgba(155, 147, 207, 0.2);
          backdrop-filter: blur(40px);
          color: #fff;

          ${Close} {
            color: #fff;
          }
        `
      : css`
          background: #fff;
          color: #000;
        `}

  @media screen and ${MEDIA_QUERY.md} {
    border-radius: ${({ side }) => (side === "right" ? "24px 0 0 24px" : "0 24px 24px 0")};
    max-width: ${({ width }) => width || 830}px;
    ${({ side }) => side}: 0;
    top: 0;
    transform: ${({ side }) => (side === "right" ? "translateX(100%)" : "translateX(-100%)")};
    width: calc(100% - 45px);

    ${Close} {
      left: ${({ side }) => (side === "left" ? "16px" : "auto")};
      right: ${({ side }) => (side === "right" ? "16px" : "auto")};
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  height: 100%;
  overflow: auto;
  overscroll-behavior: contain;
  padding: 16px;
  width: 100%;

  @media screen and ${MEDIA_QUERY.md} {
    padding: 16px 40px;
  }
`;

export const ContentNoScroll = styled.div<{ type: "header" | "footer" }>`
  padding: ${({ type }) => (type === "header" ? "16px 16px 0" : "0 16px 16px")};

  @media screen and ${MEDIA_QUERY.md} {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const Root = styled.div<{ active: boolean }>`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: visibility 0.25s ease;
  width: 100%;
  z-index: 100;

  ${({ active }) =>
    active
      ? css`
          visibility: visible;

          ${Backdrop} {
            opacity: 1;
            visibility: visible;
          }

          ${Container} {
            transform: translateX(0);
          }
        `
      : css`
          visibility: hidden;
        `}
`;
